import type { AccountInfo } from '@azure/msal-browser-1p';
import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';

export function getLoginHint(
    msalAccount?: AccountInfo,
    mailboxInfo?: MailboxInfo,
    username?: string
): string | undefined {
    if (msalAccount) {
        return msalAccount.idTokenClaims?.login_hint || msalAccount.username;
    } else if (mailboxInfo) {
        const sessionData = getAccountScopeUserSettings(mailboxInfo).SessionSettings;
        return sessionData?.LoginHint ?? sessionData?.UserPrincipalName ?? mailboxInfo.userIdentity;
    }

    return username;
}
