import { getMsalInstance, getPassedClientId } from './initializeMsalLibrary';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type TokenResponseCode from 'owa-service/lib/contract/TokenResponseCode';
import type { MailboxInfo } from 'owa-client-types';
import {
    InteractionRequiredAuthError,
    type SilentRequest,
    type SsoSilentRequest,
} from '@azure/msal-browser-1p';
import { DC_QUERY_PARAM } from './utils/constants';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getAccountFromMsal } from './utils/MsalAccounts';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { logStartCoreUsage } from 'owa-analytics-start';
import getSilentRedirectUri from './utils/getSilentRedirectUri';
import type { StringDict } from '@azure/msal-common';

const retriableErrors = [
    'post_request_failed',
    'no_network_connectivity',
    'endpoints_resolution_error',
];

export default async function fetchAccessTokenFromMsal(
    resourceUrl: string,
    mailboxInfo?: MailboxInfo,
    retryCount = 1
): Promise<TokenResponse> {
    const msalInstance = await getMsalInstance();
    const userAccount = getAccountFromMsal(msalInstance, getModuleContextMailboxInfo());

    if (userAccount) {
        let pageAlwaysVisible = self.document.visibilityState === 'visible';
        const visibilityListener = () => {
            if (self.document.visibilityState != 'visible') {
                pageAlwaysVisible = false;
            }
        };
        self.document.addEventListener('visibilitychange', visibilityListener);

        try {
            const tokenQueryParam: StringDict = {};
            if (
                isFeatureEnabled(
                    'auth-msaljs-sendDcQueryParam',
                    mailboxInfo /*mailboxInfo*/,
                    true /*dontThrowErrorIfNotInitialized*/
                )
            ) {
                // The value in the dc query param represents an AAD test instance and needs to be removed in the future.
                tokenQueryParam.dc = DC_QUERY_PARAM;
            }

            const acquireTokenRequest: SilentRequest = {
                scopes: getResourceScopes(resourceUrl),
                account: userAccount,
                tokenQueryParameters: tokenQueryParam,
                redirectUri: getSilentRedirectUri(),
            };

            const startTime = self.performance.now();

            // Try to get the token from cache first.
            const acquireTokenResponse = await msalInstance.acquireTokenSilent(acquireTokenRequest);

            self.document.removeEventListener('visibilitychange', visibilityListener);

            logStartCoreUsage('Msal-AcquireTokenSilentLatency', {
                resource: resourceUrl,
                latency: self.performance.now() - startTime,
                correlationId: acquireTokenResponse?.correlationId,
                requestId: acquireTokenResponse.requestId,
                fromCache: acquireTokenResponse.fromCache,
                retryRequest: retryCount < 1 ? true : false,
                pageVisible: pageAlwaysVisible,
                applicationId: getPassedClientId(),
            });

            return {
                AccessToken: acquireTokenResponse?.accessToken,
                TokenResultCode: 0,
                ExpiresIn: acquireTokenResponse?.expiresOn
                    ? (acquireTokenResponse?.expiresOn.getTime() - Date.now()) / 1000
                    : undefined,
                AccessTokenExpiry: acquireTokenResponse?.expiresOn?.toUTCString(),
            };
        } catch (error) {
            self.document.removeEventListener('visibilitychange', visibilityListener);
            if (retriableErrors.includes(error.errorCode?.toLowerCase()) && retryCount > 0) {
                return fetchAccessTokenFromMsal(resourceUrl, mailboxInfo, retryCount - 1);
            }

            logStartCoreUsage('Msal-AcquireTokenSilentFailure', {
                resource: resourceUrl,
                errorCode: error.errorCode,
                errorMessage: error.errorMessage,
                subError: error.subError,
                correlationId: error.correlationId,
                retryRequest: retryCount < 1 ? true : false,
                pageVisible: pageAlwaysVisible,
                applicationId: getPassedClientId(),
            });

            if (
                (error instanceof InteractionRequiredAuthError ||
                    error.errorCode === 'monitor_window_timeout') && // expired RT case
                error.errorCode === 'no_tokens_found'
            ) {
                return AcquireTokenUsingSsoSilentApi(resourceUrl, mailboxInfo);
            }

            return {
                SubErrorCode: error.errorCode,
                TokenResultCode: 2,
            };
        }
    } else {
        return AcquireTokenUsingSsoSilentApi(resourceUrl, mailboxInfo);
    }
}

async function AcquireTokenUsingSsoSilentApi(
    resourceUrl: string,
    mailboxInfo?: MailboxInfo,
    retryCount = 1
): Promise<TokenResponse> {
    try {
        const extraQueryParams: StringDict = {};
        if (
            isFeatureEnabled(
                'auth-msaljs-sendDcQueryParam',
                mailboxInfo /*mailboxInfo*/,
                true /*dontThrowErrorIfNotInitialized*/
            )
        ) {
            // The value in the dc query param represents an AAD test instance and needs to be removed in the future.
            extraQueryParams.dc = DC_QUERY_PARAM;
        }

        const sessionSettings = getUserConfiguration().SessionSettings;

        const silentRequest: SsoSilentRequest = {
            scopes: getResourceScopes(resourceUrl),
            extraQueryParameters: extraQueryParams,
            redirectUri: getSilentRedirectUri(),
        };

        if (
            isFeatureEnabled(
                'auth-msaljs-useLoginHintClaim',
                mailboxInfo,
                true /*dontThrowErrorIfNotInitialized*/
            ) &&
            sessionSettings?.LoginHint
        ) {
            silentRequest.loginHint = sessionSettings.LoginHint; //It is an opaque value set under login_hint claim by AAD to identify user's session.

            logStartCoreUsage('Msal-SsoSilent', {
                resource: resourceUrl,
                useLoginHint: true,
            });
        } else {
            silentRequest.sid = sessionSettings?.AadSessionId;

            logStartCoreUsage('Msal-SsoSilent', {
                resource: resourceUrl,
                useSid: true,
            });
        }

        const startTime = self.performance.now();

        // If there are no cached token, invoke ssoSilent to silently authenticated to the existing session.
        // Note: ssoSilent only need to be called on page load, since it always attempt to acquire a new refresh token.
        const msalInstance = await getMsalInstance();
        const ssoSilentResponse = await msalInstance.ssoSilent(silentRequest);

        logStartCoreUsage('Msal-SsoSilentLatency', {
            resource: resourceUrl,
            latency: self.performance.now() - startTime,
            correlationId: ssoSilentResponse?.correlationId,
            requestId: ssoSilentResponse.requestId,
            fromCache: ssoSilentResponse.fromCache,
            retryRequest: retryCount < 1 ? true : false,
            applicationId: getPassedClientId(),
        });

        return {
            AccessToken: ssoSilentResponse?.accessToken,
            TokenResultCode: 0,
            ExpiresIn: ssoSilentResponse?.expiresOn
                ? (ssoSilentResponse?.expiresOn.getTime() - Date.now()) / 1000
                : undefined,
            AccessTokenExpiry: ssoSilentResponse?.expiresOn?.toUTCString(),
        };
    } catch (e) {
        if (retriableErrors.includes(e.errorCode?.toLowerCase()) && retryCount > 0) {
            return AcquireTokenUsingSsoSilentApi(resourceUrl, mailboxInfo, retryCount - 1);
        }

        // If ssoSilent fails with interaction required error, we will fallback to existing behavior. i.e fetch tokens from server.
        logStartCoreUsage('Msal-SsoSilentFailure', {
            resource: resourceUrl,
            errorCode: e.errorCode,
            errorMessage: e.errorMessage,
            subError: e.subError,
            correlationId: e.correlationId,
            retryRequest: retryCount < 1 ? true : false,
            applicationId: getPassedClientId(),
        });

        return { SubErrorCode: e.errorCode, TokenResultCode: 2 };
    }
}

function getResourceScopes(resourceUrl: string): string[] {
    // https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#the-default-scope
    // Using ./default will include all scopes registered for an app in app registration portal.
    return resourceUrl.toLowerCase().endsWith('/.default')
        ? [resourceUrl]
        : [resourceUrl.replace(/\/+$/, '') + '/.default'];
}
