import type { MailboxInfo } from 'owa-client-types';
import type {
    TokenResponse,
    AuthResult,
    BridgeError,
    InitContext,
    NestedAppAuthExecutor,
    TokenRequest,
} from 'owa-nested-app-auth';
import { BridgeStatusCode } from 'owa-nested-app-auth/lib/msal-types/BridgeStatusCode';
import createBridgeError from 'owa-nested-app-auth/lib/createBridgeError';
import { acquireBrokeredAccessTokenMsal } from './acquireBrokeredAccessTokenMsal';
import { getAccountFromMsal } from '../utils/MsalAccounts';
import { createMsalInstance, getMsalInstance } from '../initializeMsalLibrary';
import {
    AuthError,
    type AuthenticationResult,
    ClientAuthError,
    ClientAuthErrorCodes,
    InteractionRequiredAuthError,
} from '@azure/msal-browser-1p';
import { getClientVersion } from 'owa-config';

function createExecutorError(
    status: BridgeStatusCode,
    subError: string,
    description?: string
): BridgeError {
    return createBridgeError(status, 'msal_executor_error', subError, description);
}

export class MsalNestedAppAuthExecutor implements NestedAppAuthExecutor {
    constructor(private readonly mailboxInfo: MailboxInfo) {
        try {
            // NAA can work independently of the full-client MSAL boot flight,
            // so initialize it here for when the boot flight is off
            createMsalInstance();
        } catch {
            // noop - error expected here if MSAL already initialized
        }
    }

    public async getInitContext(): Promise<InitContext> {
        // try to populate MSAL account caches if we have the information to
        // improve performance and allow token caching
        const accountInfo = getAccountFromMsal(
            await getMsalInstance(),
            this.mailboxInfo,
            true /*isNaa*/
        );

        return {
            sdkName: 'OwaMsal',
            sdkVersion: getClientVersion(),
            accountContext: accountInfo
                ? {
                      homeAccountId: accountInfo.homeAccountId,
                      environment: accountInfo.environment,
                      tenantId: accountInfo.tenantId,
                  }
                : undefined,
        };
    }

    public getTokenRequest(request: TokenRequest, host: string): Promise<AuthResult> {
        return this.getNestedAppAuthToken(request, true /*silent*/, host)
            .then(result => this.toNaaAuthResult(result))
            .catch(error => this.toNaaAuthError(error));
    }

    public getTokenPopup(
        request: TokenRequest,
        host: string,
        targetWindow: Window
    ): Promise<AuthResult> {
        return this.getNestedAppAuthToken(request, false /*silent*/, host, targetWindow)
            .then(result => this.toNaaAuthResult(result))
            .catch(error => this.toNaaAuthError(error));
    }

    private getNestedAppAuthToken(
        request: TokenRequest,
        silent: boolean,
        host: string,
        targetWindow?: Window
    ): Promise<AuthenticationResult> {
        if (!host) {
            throw createExecutorError(BridgeStatusCode.PersistentError, 'invalid_origin');
        }

        if (!request || !request.clientId || !request.scope) {
            throw createExecutorError(BridgeStatusCode.PersistentError, 'invalid_request');
        }

        return acquireBrokeredAccessTokenMsal(
            this.mailboxInfo,
            host,
            request,
            silent,
            targetWindow
        );
    }

    private getBridgeStatusCodeFromMsalAuthError(error: AuthError): BridgeStatusCode {
        if (error instanceof InteractionRequiredAuthError) {
            return BridgeStatusCode.UserInteractionRequired;
        } else if (error instanceof ClientAuthError) {
            switch (error.errorCode) {
                case ClientAuthErrorCodes.userCanceled:
                    return BridgeStatusCode.UserCancel;
                case ClientAuthErrorCodes.noNetworkConnectivity:
                    return BridgeStatusCode.NoNetwork;
                case ClientAuthErrorCodes.noAccountFound:
                    return BridgeStatusCode.AccountUnavailable;
                case ClientAuthErrorCodes.nestedAppAuthBridgeDisabled:
                    return BridgeStatusCode.Disabled;
                default:
                // fallthrough
            }
        }

        return BridgeStatusCode.PersistentError;
    }

    private async toNaaAuthResult(result: AuthenticationResult): Promise<AuthResult> {
        const token: TokenResponse = {
            access_token: result.accessToken,
            expires_in: result.expiresOn ? (result.expiresOn.getTime() - Date.now()) / 1000 : 0,
            id_token: result.idToken,
            scope: result.scopes.join(' '),
            authority: result.authority,
            properties: null,
        };

        return { token, account: result.account };
    }

    private async toNaaAuthError(error: unknown): Promise<AuthResult> {
        if (error instanceof AuthError) {
            // `AuthError`s are standard errors that come from MSAL.js
            throw createBridgeError(
                this.getBridgeStatusCodeFromMsalAuthError(error),
                error.errorCode,
                error.subError,
                error.errorMessage,
                {
                    correlationId: error.correlationId,
                }
            );
        }

        throw createExecutorError(BridgeStatusCode.PersistentError, 'unknown_error', String(error));
    }
}
