import { isFeatureEnabled } from 'owa-feature-flags';
import { createMsalInstance, getMsalInstance } from './initializeMsalLibrary';
import { getAccountFromMsal, getActiveAccountFromMsal } from './utils/MsalAccounts';
import { trace } from 'owa-trace';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { removeIsAuthenticated } from './isAnonymousSession';
import { setOwaAppId } from 'owa-config/lib/getOwaAppId';
import type {
    IPublicClientApplication,
    EndSessionRequest,
    AccountInfo,
} from '@azure/msal-browser-1p';
import { getPostLogoutRedirectUri } from './utils/getPostLogoutRedirectUri';
import { clearCookies } from './utils/clearCookies';
import isAccountSourceListStoreInitialized from 'owa-account-source-list-store/lib/utils/isAccountSourceListStoreInitialized';
import { isEnterpriseAccount } from './getTypeHint';

export default async function signoutFromMsal(
    skipFlightCheck?: boolean,
    isSharedABT?: boolean
): Promise<void> {
    let msalInstance: IPublicClientApplication | null = null;

    if (!!skipFlightCheck || isFeatureEnabled('auth-msaljs-clearMsalArtifactsOnLogoff')) {
        try {
            // signout should work even if MSAL hasn't been used during the session
            createMsalInstance();
        } catch {
            // noop - error expected here if MSAL already initialized
        }

        try {
            msalInstance = await getMsalInstance();
        } catch (e) {
            trace.info('MsalInitFailure-Logout ' + { Error: e });
        }
    }

    if (msalInstance) {
        let userAccount: AccountInfo | null;
        if (isAccountSourceListStoreInitialized()) {
            userAccount = getAccountFromMsal(msalInstance, getModuleContextMailboxInfo());
        } else {
            userAccount = getActiveAccountFromMsal(msalInstance);
        }
        if (userAccount != null) {
            setOwaAppId(null);
            removeIsAuthenticated();
            await clearCookies(userAccount.tenantId);
            const logoutRequest: EndSessionRequest = {
                postLogoutRedirectUri: getPostLogoutRedirectUri(userAccount, isSharedABT),
            };
            // WI: https://identitydivision.visualstudio.com/Engineering/_workitems/edit/3037323
            // Skip sending account for consumer scenarios due to the above Day 0 bug from ESTS
            // this should likely be removed once the above bug is fixed. Net effect of this is
            // users will be shown the picker to select an account when signing out of Owa.
            if (isEnterpriseAccount(userAccount)) {
                logoutRequest.account = userAccount;
            }
            await msalInstance.logoutRedirect(logoutRequest);
        }
    }
}
