import type { NestedAppAuthExecutor } from './NestedAppAuthExecutor';
import type { BridgeRequestEnvelope } from './msal-types/BridgeRequestEnvelope';
import type { BridgeResponseEnvelope } from './msal-types/BridgeResponseEnvelope';
import { processNestedAppAuthResponse } from './processNestedAppAuthResponse';
import { buildFailedBridgeResponseEnvelope } from './buildBridgeResponseEnvelope';
import { buildUnexpectedBridgeError } from './buildUnexpectedBridgeError';

export function processNaaBridgeMessage(
    request: BridgeRequestEnvelope,
    callback: NestedAppAuthExecutor,
    host: string,
    targetWindow: Window
): Promise<BridgeResponseEnvelope> {
    switch (request.method) {
        case 'GetToken':
            if (request.tokenParams) {
                return processNestedAppAuthResponse(
                    request.requestId,
                    callback.getTokenRequest(request.tokenParams, host)
                );
            }
            break;

        case 'GetTokenPopup':
            if (request.tokenParams) {
                return processNestedAppAuthResponse(
                    request.requestId,
                    callback.getTokenPopup(request.tokenParams, host, targetWindow)
                );
            }
            break;

        case 'GetInitContext':
            return processNestedAppAuthResponse(
                request.requestId,
                callback.getInitContext().then(initContext => ({ initContext }))
            );
    }

    return Promise.resolve(
        buildFailedBridgeResponseEnvelope(
            request.requestId,
            buildUnexpectedBridgeError(
                'unknown_request_method',
                `Unknown request method: ${request.method}`
            )
        )
    );
}
